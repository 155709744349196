import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { AnchorLinksBar } from "../components/AnchorLinksBar"
import { Section, Container } from "../components/Containers"
import { Text, Image } from "../components/Core"

const GivePage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    title,
    hero,
    anchors,
    heading,
    wish,
    donate,
    information,
    bequests,
    maintenance,
    association
  } = data.allUniquePagesJson.nodes[0]

  const rootUrl = title.substring(0, title.length - 1)

  return (
    <Layout className="give-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Image wrapperClassName="gradient-curtain" useAR publicId={hero.image} />

      <AnchorLinksBar rootUrl={rootUrl} links={anchors} />

      <Section>
        <Container small>
          <Text text={heading} />
        </Container>
      </Section>

      <Section className="give-page__information">
        <Container small>
          <Text as="p" className="bold mb-2" text="More Information" />
          <div className="even-grid--2 mobile-collapse">
            {information.map((block, i) => (
              <Text key={i} text={block} />
            ))}
          </div>
        </Container>
      </Section>

      <Container small>
        <Section colorBack className="give-page__donate">
          <div className="even-grid--2 mobile-collapse">
            <Text text={donate.left} />
            <Text
              className="give-page__donate-content mt-3--mobile"
              text={donate.right}
            />
          </div>
        </Section>
      </Container>

      <Section>
        <Container small>
          <Text className="bold mb-2" text={wish.heading} />
        </Container>
        <Container medium className="mt-3">
          <div className="give-grid--2">
            <Image publicId={wish.image} />
            <Text className="give-grid__content" text={wish.content} />
          </div>
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text className="bold mb-4" text={bequests.content} />
          <div className="even-grid--2 mobile-collapse">
            {bequests.blocks.map((block, i) => (
              <Text key={i} text={block} />
            ))}
          </div>
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text className="bold mb-2" text={maintenance.heading} />
        </Container>
        <Container medium className="mt-3">
          <div className="give-grid--2">
            <Image publicId={maintenance.image} />
            <Text className="give-grid__content" text={maintenance.content} />
          </div>
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text className="bold mb-4" text={association.content} />
          {/* <div className="even-grid--2 mobile-collapse">
            {association.blocks.map((block, i) => (
              <Text key={i} text={block} />
            ))}
          </div> */}
          <h3 className="mt-4 has-text-centered-desktop">
            Our Corporate and Foundation Supporters
          </h3>
          <Image className="mt-3 mx-auto" publicId={association.sponsors} />
        </Container>
      </Section>
    </Layout>
  )
}

export const giveQuery = graphql`
  query givequery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
        }
        anchors {
          text
          id
        }
        heading
        wish {
          heading
          content
          image
        }
        information
        donate {
          left
          right
        }
        maintenance {
          heading
          content
          image
        }
        bequests {
          content
          blocks
        }
        association {
          content
          # blocks
          sponsors
        }
      }
    }
  }
`

export default GivePage
